import { template as template_8e4f673e157d4740aac7e74e05dddc8c } from "@ember/template-compiler";
const SidebarSectionMessage = template_8e4f673e157d4740aac7e74e05dddc8c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
