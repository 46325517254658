import { template as template_58f3f96a56484820bca6ab185869cf6f } from "@ember/template-compiler";
const FKControlMenuContainer = template_58f3f96a56484820bca6ab185869cf6f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
